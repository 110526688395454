import React, { Component } from 'react';
import './Channel.css';

class Channel extends Component {

  render() {
    return (
      <div className={'channel' + (this.props.selected ? ' selected' : '') + (this.props.disabled ? ' disabled' : '')}
        onClick={() => this.props.disabled ? null : this.props.onSelect()}>
        <span className="check">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9.00006" r="9" fill="white"></circle>
            <path d="M15.0469 7.10162C15.0469 7.30084 14.9766 7.48834 14.8359 7.62897L8.47266 13.9922C8.33203 14.1329 8.13281 14.2149 7.93359 14.2149C7.74609 14.2149 7.54688 14.1329 7.40625 13.9922L3.16406 9.75006C3.02344 9.60944 2.95312 9.42194 2.95312 9.22272C2.95312 9.0235 3.02344 8.82428 3.16406 8.68365L4.23047 7.62897C4.37109 7.48834 4.55859 7.40631 4.75781 7.40631C4.95703 7.40631 5.14453 7.48834 5.28516 7.62897L7.93359 10.2774L12.7148 5.50787C12.8555 5.36725 13.043 5.28522 13.2422 5.28522C13.4414 5.28522 13.6289 5.36725 13.7695 5.50787L14.8359 6.56256C14.9766 6.70319 15.0469 6.9024 15.0469 7.10162ZM18 9.00006C18 4.03131 13.9688 6.10352e-05 9 6.10352e-05C4.03125 6.10352e-05 0 4.03131 0 9.00006C0 13.9688 4.03125 18.0001 9 18.0001C13.9688 18.0001 18 13.9688 18 9.00006Z" fill="#FF6915"></path>
          </svg>
        </span>
        <img style={{maxWidth:'255px', maxHeight:'340px'}} alt="Jornal" src={this.props.image} />
      </div>
    );
  }
}

export default Channel;
