import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Main from './Screens/Main';
import Error from './Screens/Error';

class AppRouter extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/error" component={Error} />
          <Route render={() => <Redirect to='/error?status=404' />}  />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
