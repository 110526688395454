import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import AppRouter from './AppRouter'
import AuthUrls from './Common/AuthUrls';
import './App.css';
import { NSCTotalService } from './Services/NSCTotalService';


class App extends Component {

  constructor(props) {
    super(props);
    this.overFlowMenuRef = React.createRef();
    this.state = {
      sideBarLinks: [],
      topBarMenu: [],
      showMenu: {},
      showMobileMenu: {},
      show: false
    };
  }

  login() {
    window.location.href = AuthUrls.buildUrl('login', null, process.env.REACT_APP_REDIRECT_URI);
  }

  profile() {
    window.location.href = AuthUrls.buildUrl('profile', null, process.env.REACT_APP_REDIRECT_URI);
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove("given_name");
    window.location.reload(false);
  }

  handleMenuChange = () => {
    this.setState({ showMobileMenu: {} });
    const menuVisibility = (this.state.showMenu.display === 'block') ? { display: 'none' } : { display: 'block' };
    this.setState({ showMenu: menuVisibility });
  }

  handleMenuMobileChange = () => {
    this.setState({ showMenu: {} });
    const menuStyle = (this.state.showMobileMenu.width === '268px') ? { width: '0px', left: '-300px' } : { width: '268px', left: '0px' };
    this.setState({ showMobileMenu: menuStyle });
  }

  async componentDidMount() {
    const results = await Promise.all([
      NSCTotalService.getMenus()
    ]);

    const allMenus = results[0]

    this.setState({
      sideBarLinks: allMenus.sidebar.links,
      topBarMenu: allMenus.topbar.links,
    });
  }

  handleSubmenuClick = (items) => {
    this.setState({
      isSubmenu: true,
      sideBarLinks: items,
    });
    this.overFlowMenuRef.current.scrollTo({ top: '0', behavior: 'smooth' });
  }

  handleBackButton = () => {
    NSCTotalService.getMenus().then((menus) => {
      this.setState({
        isSubmenu: false,
        sideBarLinks: menus.sidebar.links,
      })
    });
  }

  renderMyAccount() {
    const { classes } = this.props;
    
    const cookies = new Cookies();
    let givenName = cookies.get('given_name');
    
    if (this.state.show && givenName) {
      return (
        <ul tabIndex="0" className={classes.topBar}>
          <li><button className="styledButton" onClick={this.profile}>Minha conta</button></li>
          <li><button className="styledButton" onClick={this.logout}>Sair</button></li>
        </ul>
      )
    }
    return null;
  }

  render() {
   
    const cookies = new Cookies();

    return (
      <div className="app">
      <div className="header">
          <div className="container">
            <div className="logo">
              <a href="https://www.nsctotal.com.br">
                <svg viewBox="0 0 207 77" className="sc-kpOJdX gazDxd">
                  <defs>
                    <path id="a" d="M91.955 77H0V0h91.955v77z"></path>
                  </defs>
                  <g fillRule="evenodd">
                    <mask id="b" fill="#fff">
                      <use xlinkHref="#a"></use>
                    </mask>
                    <path
                      d="M82.549 37.91c-2.015 3.177-5.122 4.682-9.406 4.682-7.473 0-11.84-5.184-11.84-12.04 0-6.772 4.787-11.956 11.925-11.956 3.275 0 6.55 1.004 8.901 4.264.588.836.336 1.589-.42 2.258l-2.015 1.337c-.84.67-1.428.502-2.184-.25-.923-.837-1.931-1.254-3.61-1.254-3.527 0-5.123 2.424-5.123 5.601 0 3.093 1.68 5.685 5.206 5.685 1.764 0 2.94-.585 3.863-1.588.672-.67 1.428-.837 2.184-.251l2.015 1.421c.84.586 1.092 1.338.504 2.09zm-33.843 4.598c-4.282 0-7.053-.836-9.573-2.592-.756-.585-.84-1.505-.336-2.257l1.176-1.756c.587-.836 1.26-1.003 2.184-.502 1.595.92 3.358 1.338 5.71 1.338 1.847 0 3.107-.334 3.107-1.505 0-1.17-.84-1.505-3.779-1.923-5.71-.752-8.062-3.01-8.062-7.107 0-4.598 4.115-7.357 9.573-7.357 3.276 0 5.71.836 7.894 2.425.84.669.924 1.505.336 2.34l-1.092 1.506c-.588.836-1.26.92-2.183.501-1.511-.836-2.94-1.087-4.535-1.087-1.847 0-2.855.419-2.855 1.422-.084 1.087.84 1.338 3.863 1.84 5.458 1.002 7.978 2.841 7.978 7.607 0 4.18-3.611 7.107-9.406 7.107zm-13.772-2.09c0 1.003-.671 1.672-1.68 1.672h-4.282c-1.008 0-1.596-.669-1.596-1.672v-11.37c0-2.76-1.26-4.097-3.527-4.097-2.939 0-4.87 2.006-4.87 6.52v8.947c0 1.003-.588 1.672-1.596 1.672h-4.367c-1.007 0-1.595-.669-1.595-1.672V20.77c0-1.087.588-1.672 1.595-1.672h3.192c1.007 0 1.68.418 1.847 1.338.168.501.252 1.086.336 1.588 1.763-2.09 4.282-3.428 7.474-3.428 5.878 0 9.07 3.68 9.07 10.702v11.12zM86.916.035L3.946 10.486C1.68 10.821 0 12.66 0 14.918v30.684c0 1.922 1.26 3.678 3.107 4.263l82.97 26.922c2.854.92 5.878-1.254 5.878-4.264V4.467c0-2.676-2.352-4.766-5.04-4.432z"
                      mask="url(#b)"
                    ></path>
                    <path d="M109.778 42.363c1.749 0 3.234-.396 4.356-1.023v-4.917c-.759.363-1.551.594-2.376.594-1.155 0-1.716-.528-1.716-1.782v-6.039h4.158v-5.049h-4.158v-4.521h-6.27v4.521h-2.112v5.049h2.112v7.26c0 4.257 2.244 5.907 6.006 5.907zm16.236.066c-5.709 0-9.834-4.191-9.834-9.273v-.066c0-5.082 4.158-9.339 9.9-9.339 5.709 0 9.834 4.191 9.834 9.273v.066c0 5.082-4.158 9.339-9.9 9.339zm.066-5.313c2.277 0 3.729-1.815 3.729-3.96v-.066c0-2.178-1.584-4.026-3.795-4.026-2.277 0-3.729 1.815-3.729 3.96v.066c0 2.178 1.584 4.026 3.795 4.026zm19.569 5.247c1.749 0 3.234-.396 4.356-1.023v-4.917c-.759.363-1.551.594-2.376.594-1.155 0-1.716-.528-1.716-1.782v-6.039h4.158v-5.049h-4.158v-4.521h-6.27v4.521h-2.112v5.049h2.112v7.26c0 4.257 2.244 5.907 6.006 5.907zm12.243.033c-3.399 0-6.006-1.947-6.006-5.511v-.066c0-3.696 2.805-5.61 6.996-5.61 1.65 0 3.267.33 4.29.693v-.297c0-1.848-1.155-2.904-3.564-2.904-1.881 0-3.333.363-4.917.99l-1.287-4.422c2.013-.825 4.158-1.386 7.227-1.386 3.201 0 5.379.759 6.798 2.178 1.353 1.32 1.914 3.168 1.914 5.676V42h-6.204v-1.848c-1.221 1.353-2.904 2.244-5.247 2.244zm2.244-3.927c1.815 0 3.102-1.155 3.102-2.937v-.825c-.627-.264-1.518-.462-2.442-.462-1.815 0-2.871.891-2.871 2.211v.066c0 1.221.924 1.947 2.211 1.947zM172.676 42h6.27V17.91h-6.27V42z"></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
         <hr />
        
         
        </div>
        <AppRouter />
        <div className="footer">
          <img src="/img/logos.png" alt="Logos" />
          <h1>Atendimento:</h1>
          <p>0800 644 4001 - Totas as cidades</p>
          <p>email: atendimento.nsc@nsc.com.br</p>
        </div>
      </div>
    );
  }
}

export default App;