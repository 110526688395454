import React, { Component } from 'react';
import './Modal.css';

class Modal extends Component {

  render() {
    return (
      <div className='modal' hidden={this.props.hidden}>
        <div className="overlay">
          <div className="content">
            <div className="close" onClick={this.props.onClose}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="14"
                  x2="14" y2="1"
                  stroke="#666"
                  strokeWidth="3" />
                <line x1="1" y1="1"
                  x2="14" y2="14"
                  stroke="#666"
                  strokeWidth="3" />
              </svg>
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
