export function getEnv(name, { required = true, defaultValue = undefined } = {}) {
    const value = process.env[name];
  
    if (value) {
      return value;
    }
  
    if (required) {
      throw new Error(`Missing environment variable "${name}"`);
    }
  
    return defaultValue;
  }
  