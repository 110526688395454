import React, { Component } from 'react';
import queryString from 'query-string'
import Cookies from 'universal-cookie';
import './Main.css';
import AuthUrls from '../Common/AuthUrls';
import Channel from '../Compoments/Channel';
import Modal from '../Compoments/Modal';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: 'DC',
      authenticated: false,
      isSubscriber: false,
      hasDocument: false,
      modalClosed: false,
    };

    this.login = this.login.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.access_token && values.id_token) {
      this.props.history.push({ search: '' });      
      const raw = atob(values.id_token.split('.')[1]);
      const json = JSON.parse(raw);

      const cookies = new Cookies();
      cookies.set('given_name', json['given_name']);
      const isSubscriber = /(^|;)\s*(AN|DC|JSC|NSC_TOTAL)\s*($|;)/.test(json['nsc:products'] || '');

      if (isSubscriber) {
        window.location.href = AuthUrls.flipUrl(values.state);
        return;
      }

      this.setState({
        selected: values.state,
        authenticated: true,
        isSubscriber: isSubscriber,
        hasDocument: !!json['nsc:document'],
      });
    }

    setTimeout(() => document.getElementById('root').style.display = '', 50);
  }

  render() {
    return (
      <div className="main">
        <h1>Selecione o jornal que você deseja acessar:</h1>
        <h2>Assinante NSC (DC, AN, Santa ou NSC Total) tem acesso ilimitado ao portal e também pode ler todas as edições digitais do jornal de fim de semana. Escolha o título que você quer ler e clique em Entrar.</h2>
        <div className="row channels">
          <div className="column">
            <Channel image="https://nsc-capas.s3-sa-east-1.amazonaws.com/dc-capa-flip.png"
              selected={this.state.selected === 'DC'}
              onSelect={() => this.setState({ selected: 'DC' })} />
          </div>
          <div className="column">
            <Channel image="https://nsc-capas.s3-sa-east-1.amazonaws.com/an-capa-flip.png"
              selected={this.state.selected === 'AN'}
              onSelect={() => this.setState({ selected: 'AN' })} />
          </div>
          <div className="column">
            <Channel image="https://nsc-capas.s3-sa-east-1.amazonaws.com/jsc-capa-flip.png"
              selected={this.state.selected === 'JSC'}
              onSelect={() => this.setState({ selected: 'JSC' })} />
          </div>
        </div>
        <button onClick={this.login} className="btn btn-continue">Entrar</button>
        <p className="message">Se você não é assinante NSC, <a href={process.env.REACT_APP_STORE_URL}>clique aqui</a> para adquirir sua assinatura</p>
        <Modal hidden={this.state.modalClosed || !this.state.authenticated || this.state.hasDocument} onClose={this.closeModal}>
          <h1>O Jornal Digital é exclusivo para assinantes NSC.</h1>
          <p>Para assinar, <a href={process.env.REACT_APP_STORE_URL}>clique aqui</a> , ou SE você é assinante, edite seu cadastro com CPF para identificarmos sua assinatura, <a href={AuthUrls.profileUrl()}>clique aqui</a>.</p>
        </Modal>
        <Modal hidden={this.state.modalClosed || !this.state.authenticated || !this.state.hasDocument || this.state.isSubscriber} onClose={this.closeModal}>
          <h1>O Jornal Digital é exclusivo para assinantes NSC.</h1>
          <p>Para acessar você precisa assinar, <a href={process.env.REACT_APP_STORE_URL}>assine aqui</a>.</p>
        </Modal>
      </div>
    );
  }

  login() {
    if (this.state.authenticated) {
      this.setState({ modalClosed: false });
    } else {
      window.location.href = AuthUrls.athorizeUrl(this.state.selected);
    }
  }

  closeModal() {
    this.setState({ modalClosed: true });
  }
}

export default Main;
