class AuthUrls {

  static loginUrl() {
    return this.buildUrl('api/v1/authorize', 'token', process.env.REACT_APP_REDIRECT_URI);
  }

  static athorizeUrl(state) {
    const redirectUri = process.env.REACT_APP_REDIRECT_URI || 'https://flip.nsctotal.com.br/';
    return this.buildUrl('api/v1/authorize', 'token', redirectUri, state);
  }

  static flipUrl(channel) {
    let redirectUri
    if (channel === 'AN'){
       redirectUri = process.env[`REACT_APP_${channel}_REDIRECT_URI`] || 'https://anoticia.presslab.com.br/';
    }else if(channel === 'DC'){
       redirectUri = process.env[`REACT_APP_${channel}_REDIRECT_URI`] || 'https://diariocatarinense.presslab.com.br/';
    }else if(channel === 'JDC'){
       redirectUri = process.env[`REACT_APP_${channel}_REDIRECT_URI`] || 'https://jornaldesantacatarina.presslab.com.br/';
    }else{
       redirectUri = process.env[`REACT_APP_${channel}_REDIRECT_URI`] ;
    }
    return this.buildUrl('api/v1/authorize', 'code', redirectUri);
  }

  static channelUrl(channel) {
    return this.buildUrl('api/v1/authorize', 'code', process.env[`REACT_APP_${channel}_REDIRECT_URI`]);
  }

  static profileUrl() {
    return this.buildUrl('profile', 'token', process.env.REACT_APP_REDIRECT_URI);
  }

  static buildUrl(path, responseType, redirectUri, state) {
    const base = process.env.REACT_APP_NSC_AUTH_URL.replace(/\/$/, '');
    const encodedResponseType = encodeURIComponent(responseType);
    const encodedClientId = encodeURIComponent(process.env.REACT_APP_CLIENT_ID);
    const encodedRedirectUri = encodeURIComponent(redirectUri);
    const encodedState = encodeURIComponent(state || '');
    return `${base}/${path}?response_type=${encodedResponseType}&client_id=${encodedClientId}&redirect_uri=${encodedRedirectUri}&state=${encodedState}`;
  }
}

export default AuthUrls;