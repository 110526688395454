import { ApolloClient, gql } from 'apollo-boost';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { getEnv } from '../Helpers/Utils';

const client = new ApolloClient({
  link: createHttpLink({
    uri: getEnv('REACT_APP_NSC_TOTAL_URL'),
    useGETForQueries: true
  }),
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: [],
        },
      },
    })
  })
})

const menuQuery = gql`
query HeaderMenu {
  topbar: menuByName(name: "menu-topbar") {
    links {
      ...MenuLink
      links {
        ...MenuLink
        links {
          ...MenuLink
        }
      }
    }
  }
  sidebar: menuByName(name: "sidebar-menu-nsc") {
    links {
      ...MenuLink
      links {
        ...MenuLink
        links {
          ...MenuLink
        }
      }
    }
  }
  socialMedias: menuByName(name: "social-medias-menu") {
    links {
      ...MenuLink
    }
  }
}
fragment MenuLink on MenuLink {
  label
  url {
    path
  }
}
`

export class GraphQLError extends Error {
  constructor(messages = []) {
    super(`GraphQL errors: ${messages.join(', ')}`);
    this.name = 'GraphQLError';
  }
}

export class NSCTotalService {

  static getMenus() {
    return this.executeQuery(menuQuery);
  }

  static async executeQuery(query, variables) {
    const result = await client.query({ query, variables });

    if (result.errors && result.errors.length) {
      console.error('GraphQL error', result.errors);
      const messages = result.errors.map(e => e.message);
      throw new GraphQLError(messages);
    }

    return result.data;
  }
}

